import store from '@/store'
/* eslint-disable import/prefer-default-export */
export const referencePeriods = () => {
  // eslint-disable-next-line arrow-body-style
  const fetchReferencePeriods = payload => {
    return store.dispatch('rules-reference-periods/fetchReferencePeriods', payload)
  }
  // eslint-disable-next-line arrow-body-style
  const fetchPeriod = periodId => {
    return store.dispatch('rules-reference-periods/fetchReferencePeriod', periodId)
  }

  const saveReferencePeriod = payload => store.dispatch('rules-reference-periods/saveReferencePeriod', payload)

  const deleteReferencePeriod = periodId => store.dispatch('rules-reference-periods/deleteReferencePeriod', periodId)

  return {
    fetchReferencePeriods,
    fetchPeriod,
    saveReferencePeriod,
    deleteReferencePeriod,
  }
}
