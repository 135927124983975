<template>
  <div>
    <b-row class="match-height">
      <b-col
        xl="12"
      >
        <b-card no-body>
          <b-row class="pl-1">
            <b-col
              xl="2"
              md="6"
              class="pt-1 pb-1 pr-0"
            >
              <b-form-select
                v-model="referencePeriod"
                :options="referencePeriodOptions"
                @change="loadStats()"
              />
            </b-col>
            <b-col
              xl="2"
              md="2"
              class="pt-1 pb-1 pr-0"
            >
              <b-form-select
                v-model="timeframeFilter"
                :options="timeframeFilterOptions"
                @change="loadStats()"
              />
            </b-col>
            <b-col
              xl="2"
              md="2"
              class="pt-1 pb-1 pr-0"
            >
              <b-form-select
                v-model="viewByFilter"
                :options="viewByFilterOptions"
                @change="loadStats()"
              />
            </b-col>
            <b-col
              xl="2"
              md="6"
              class="pt-1 pb-1 pr-0"
            >
              <b-form-select
                v-model="accumulatedFilter"
                :options="accumulatedFilterOptions"
                @change="loadStats()"
              />
            </b-col>
            <b-col
              xl="2"
              md="2"
              class="pt-1 pb-1 pr-0"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                @click="loadStats()"
              >
                <feather-icon icon="RotateCwIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card no-body>
          <b-card-header>
            <!-- title and text -->
            <div>
              <b-card-title>
                Seances Stats by Source<b-spinner
                  v-if="dataIsLoading"
                  class="ml-2"
                  label="Loading..."
                />
              </b-card-title>
              <b-card-text class="mb-0" />
            </div>
          <!-- datepicker -->
          </b-card-header>

          <b-card-body>
            <div v-if="seancesStatsBySourceHasResult">
              <vue-apex-charts
                ref="realtimeSeanceChart"
                type="donut"
                height="350"
                :options="donutChart.chartOptions"
                :series="donutChart.series"
              />
            </div>
            <div v-if="!seancesStatsBySourceHasResult && !dataIsLoading">
              No records found
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <b-card no-body>
          <b-card-header>
            <!-- title and text -->
            <div>
              <b-card-title>
                Total Counts
                <b-spinner
                  v-if="dataIsLoadingCounts"
                  class="ml-2"
                  label="Loading..."
                />
              </b-card-title>
              <b-card-text class="mb-0" />
            </div>
          </b-card-header>
          <b-card-body>
            <div v-if="!seancesStatsByCountsHasResult && !dataIsLoadingCounts">
              No records found
            </div>
            <div
              v-if="seancesStatsByCountsHasResult"
              class="d-flex justify-content-between m-2"
            >
              <div class="text-center">
                <b-card-text class="mb-50">
                  Total Seances
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ totalCounts.totalSeances }}</span>
              </div>
              <div class="text-center">
                <b-card-text class="mb-50">
                  Total Seances Hours
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ totalCounts.totalSeanceHours }}</span>
              </div>
              <div class="text-center">
                <b-card-text class="mb-50">
                  Total Premium Hours
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ totalCounts.totalPremiumHours }}</span>
              </div>
              <div class="text-center">
                <b-card-text class="mb-50">
                  Total Amount (EUR)
                </b-card-text>
                <span class="font-large-1 font-weight-bold">{{ formatTotalAmount(totalCounts.totalAmount) }}</span>
              </div>
            </div>
            <div v-if="seancesStatsByCountsHasResult">
              <vue-apex-charts
                ref="realtimeSeanceChartLine"
                type="line"
                height="350"
                :options="chartLineOptions"
                :series="seriesLine"
              />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
  BCardTitle,
  BCardText, BFormSelect,
  BSpinner,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'
import { premiumSeances } from '@/views/pages/seances/premiumSeances'
import { premiumTypes } from '@/views/pages/premium-types/premiumTypes'
import { referencePeriods } from '../reference-period/referencePeriods'

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#fcd61a',
    series2: '#2b9bf4',
  },
}

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BFormSelect,
    BSpinner,
    BCol,
    BRow,
    BButton,
  },
  data() {
    return {
      accumulatedFilter: '0',
      accumulatedFilterOptions: [
        { value: '0', text: 'Period' },
        { value: '1', text: 'Accumulated' },
      ],
      timeframeFilter: 'm',
      viewByFilter: 'w',
      totalCounts: {
        totalSeances: 0,
        totalAmount: 0,
        totalPremiumHours: 0,
        totalSeanceHours: 0,
      },
      timeframeFilterOptions: [
        { value: 'm', text: 'This Month' },
        { value: 'q', text: 'This Quarter' },
        { value: 'y', text: 'This Year' },
        { value: 'ytd', text: 'Year to Date' },
      ],
      viewByFilterOptions: [
        { value: 'd', text: 'View by Day' },
        { value: 'w', text: 'View by Week' },
        { value: 'm', text: 'View by Month' },
      ],
      dataIsLoading: false,
      dataIsLoadingCounts: false,
      seancesStatsBySourceHasResult: false,
      seancesStatsByCountsHasResult: false,
      referencePeriod: 1,
      referencePeriodCommon: 1,
      referencePeriodOptions: [
      ],
      premiumTypeOptions: [],
      seriesLine: [{
        name: 'Total Seances ',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      }, {
        name: 'Total Seances Hours',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      }, {
        name: 'Total Premium Hours',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      }, {
        name: 'Total Amount',
        data: [235, 41, 136, 426, 245, 648, 352, 533, 421],
      }],
      chartLineOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false, // <== line to add
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth', // 'straight','smooth','stepline'
        },
        title: {
          text: '',
          align: 'left',
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar'],
        },
      },
      donutChart: {
        series: [999, 1],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series2,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Total',
                    formatter() {
                      return '100%'
                    },
                  },
                },
              },
            },
          },
          labels: ['Admin', 'Event'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.loadPremiumTypes()
    this.loadReferencePeriods()
    // this.reloadStats()
    // this.reloadStatsByTotalCounts()
  },
  methods: {
    formatDate(date) {
      return new Date(date).toISOString().slice(0, 10)
    },
    formatTotalAmount(amount) {
      const amountEuro = (amount / 100)
      return `${amountEuro}`
    },
    loadStats() {
      this.reloadStats()
      this.reloadStatsByTotalCounts()
    },
    getCurrentReferencePeriod() {
      let referencePeriodSelected = 1
      if (this.referencePeriodOptions.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.referencePeriodOptions.length; i++) {
          const periodStart = Date.parse(this.referencePeriodOptions[i].period_start)
          const periodEnd = Date.parse(this.referencePeriodOptions[i].period_end)
          const currentYear = Date.parse(new Date().getFullYear())

          if ((currentYear <= periodEnd && currentYear >= periodStart)) {
            referencePeriodSelected = this.referencePeriodOptions[i].value
          }
        }
      }
      return referencePeriodSelected
    },
    loadPremiumTypes() {
      this.premiumTypeOptions = store.getters['rules-premium-types/GET_PREMIUM_TYPES']
      if (this.premiumTypeOptions.length === 0) {
        const searchData = { search: '', itemsPerPage: 100, currentPage: 1 }
        const { fetchPremiumTypes } = premiumTypes()
        fetchPremiumTypes(searchData)
          .then(response => {
            if (response.data.status === 200) {
              if (response.data.data.records) {
                for (let i = 0; i < response.data.data.records.length; i += 1) {
                  this.premiumTypeOptions.push({
                    value: response.data.data.records[i].premium_type,
                    text: response.data.data.records[i].premium_type,
                    premium_type: response.data.data.records[i].premium_type,
                    enforce_precedence: response.data.data.records[i].enforce_precedence,
                    precedence_premium_type: response.data.data.records[i].precedence_premium_type,
                    created_on: this.formatDate(response.data.data.records[i].created_on),
                    updated_on: this.formatDate(response.data.data.records[i].updated_on),
                  })
                }
                store.commit('rules-premium-types/UPDATE_PREMIUM_TYPES', this.premiumTypeOptions)
              }
            }
          })
      }
    },
    loadReferencePeriods() {
      this.referencePeriodOptions = store.getters['rules-reference-periods/GET_REFERENCE_PERIODS']
      if (this.referencePeriodOptions.length === 0) {
        const searchData = { search: '', itemsPerPage: 1000, currentPage: 1 }
        const { fetchReferencePeriods } = referencePeriods()
        fetchReferencePeriods(searchData)
          .then(response => {
            if (response.data.status === 200) {
              if (response.data.data.reference_periods) {
                for (let i = 0; i < response.data.data.reference_periods.length; i += 1) {
                  this.referencePeriodOptions.push({
                    value: response.data.data.reference_periods[i].id,
                    text: response.data.data.reference_periods[i].name,
                    period_start: this.formatDate(response.data.data.reference_periods[i].period_start),
                    period_end: this.formatDate(response.data.data.reference_periods[i].period_end),
                  })
                }
                store.commit('rules-reference-periods/UPDATE_REFERENCE_PERIODS', this.referencePeriodOptions)
                this.referencePeriod = this.getCurrentReferencePeriod()
                this.loadStats()
              }
            }
          })
      } else {
        this.referencePeriod = this.getCurrentReferencePeriod()
        this.loadStats()
      }
    },
    reloadStats() {
      this.dataIsLoading = true
      const searchData = { reference_period_id: this.referencePeriod, timeframe: this.timeframeFilter }
      const { fetchSeanceStatsBySource } = premiumSeances()
      if (this.referencePeriod != null) {
        fetchSeanceStatsBySource(searchData)
          .then(response => {
            this.dataIsLoading = false
            this.donutChart.series = []
            this.donutChart.chartOptions.labels = []
            if (response.data.data && response.data.data.length > 0) {
              this.seancesStatsBySourceHasResult = true
              for (let i = 0; i < response.data.data.length; i += 1) {
                this.donutChart.series[i] = response.data.data[i].count
                this.donutChart.chartOptions.labels[i] = response.data.data[i].source
              }
            } else {
              this.seancesStatsBySourceHasResult = false
              this.donutChart.series = []
              this.donutChart.chartOptions.labels = []
            }
          })
      } else {
        this.dataIsLoading = false
      }
    },
    reloadStatsByTotalCounts() {
      this.dataIsLoadingCounts = true
      const searchData = {
        reference_period_id: this.referencePeriod, timeframe: this.timeframeFilter, view_by: this.viewByFilter, is_cumulative: this.accumulatedFilter,
      }
      const { fetchSeanceStatsByTotals } = premiumSeances()
      if (this.referencePeriod != null) {
        fetchSeanceStatsByTotals(searchData)
          .then(response => {
            this.dataIsLoadingCounts = false
            if (response.data.data.total_seances != null) {
              this.seancesStatsByCountsHasResult = true
              this.totalCounts.totalSeances = response.data.data.total_seances
              this.totalCounts.totalPremiumHours = response.data.data.total_premium_hours
              this.totalCounts.totalSeanceHours = response.data.data.total_seance_hours
              this.totalCounts.totalAmount = response.data.data.total_amount

              // line curve chart

              this.chartLineOptions = {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: true,
                    tools: {
                      download: false, // <== line to add
                    },
                  },
                },
                colors: ['#2b9bf4', '#25e6a4', '#ff6077'],
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth', // 'straight','smooth','stepline'
                },
                title: {
                  text: '',
                  align: 'left',
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: response.data.data.chart_series.category_names,
                },
                yaxis: [
                  {
                    seriesName: 'Total Seances Hours',
                    opposite: false,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: '#2b9bf4',
                    },
                    labels: {
                      style: {
                        colors: '#2b9bf4',
                      },
                    },
                    title: {
                      text: 'Hours',
                      style: {
                        color: '#2b9bf4',
                      },
                    },
                  },
                  {
                    seriesName: 'Total Seances Hours',
                    show: false,
                  },
                  {
                    seriesName: 'EUR',
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: '#ff6077',
                    },
                    labels: {
                      style: {
                        colors: '#ff6077',
                      },
                    },
                    title: {
                      text: 'EUR',
                      style: {
                        color: '#ff6077',
                      },
                    },
                  },
                ],
              }

              this.seriesLine = [
              /* {
                name: 'Total Seances ',
                data: response.data.data.chart_series.total_seances_counts,
              }, */
                {
                  name: 'Total Seances Hours',
                  data: response.data.data.chart_series.total_seances_hours,
                  type: 'line',
                },
                {
                  name: 'Total Premium Hours',
                  type: 'line',
                  data: response.data.data.chart_series.total_premium_hours,
                },
                {
                  name: 'Total Amount',
                  type: 'line',
                  data: response.data.data.chart_series.total_amount,
                }]
            } else {
              this.seancesStatsByCountsHasResult = false
              this.seriesLine = []
              this.chartLineOptions = {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: true,
                    tools: {
                      download: false, // <== line to add
                    },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth', // 'straight','smooth','stepline'
                  width: [4, 4, 4],
                },
                plotOptions: {
                  bar: {
                    columnWidth: '5%',
                  },
                },
                title: {
                  text: '',
                  align: 'left',
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: [],
                },
              }
            }
          })
      } else {
        this.dataIsLoadingCounts = false
      }
    },
  },
}
</script>

<style>

</style>
